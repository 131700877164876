<template>
  <component :is="template"></component>
</template>
<script>

import { mapGetters } from "vuex";
import FinalizadoList from "./finalizadoList";

export default {
  components: {
    FinalizadoList,
  },
  data() {
    return {
      finalizado: {
        estado : true,
      },
    };
  },
  mounted() {
    let muta = {};

    if (this.$route.query.oper === undefined) {
      muta.oper = "list";
      muta.finalizado = this.finalizado;
    } else {
      muta.oper = this.$route.query.oper;
    }
    
    this.$store.commit("gstupa/OPERACION_FINALIZADO", muta);
  },

  computed: { ...mapGetters({ template: "gstupa/getFinalizadoTpl" }) },
};
</script>
<style>
</style>